import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import {
  CompiereDataGridType,
  DataStore,
  DataStoreKey,
  DataStoreRequest,
  DataStoreStatus,
} from '@compiere-ws/models/compiere-data-json';
import { PrimeAccordionComponent } from '@iupics-components/overrided/prime-accordion/prime-accordion.component';
import PrimeFieldsetComponent from '@iupics-components/overrided/prime-fieldset/prime-fieldset.component';
import SpecificWindowUiComponent from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import StepperUiComponent from '@iupics-components/standard/fields/stepper-ui/stepper-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { WindowFactoryUtils } from '@iupics-manager/managers/ui-creator/window-factory/window-factory-utils';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { AbstractDynamicView } from '@iupics-manager/models/abstract-dynamic-view';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { createComponent } from '@iupics-util/tools/component-cache-loader';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { DynamicContainerDirective } from '../../../../iupics-util/directives/dynamic-container.directive';
import AccordionUiComponent from '../accordion-ui/accordion-ui.component';
import AdditionalInfoUiComponent from '../additional-info-ui/additional-info-ui.component';
import EditViewUiComponent from '../edit-view-ui/edit-view-ui.component';
import { EditViewUtils } from '../edit-view-ui/utils/edit-view.utils';
import { VFormatValidator } from './utils/VFormatValidator';
@Component({
  selector: 'iu-edit-tab-ui',
  templateUrl: './edit-tab-ui.component.html',
  styleUrls: ['./edit-tab-ui.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [PrimeFieldsetComponent, PrimeAccordionComponent, DynamicContainerDirective],
})
export default class EditTabUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit, OnDestroy {
  #cd = inject(ChangeDetectorRef);

  private lastCssClass = '';
  isDisplay: 'inline' | 'none' = 'inline';
  isExpand = true;
  isTopEditTab = false;
  isCollapsed = false;
  childrenCreated = false;
  dataContainers: AbstractDataContainer[] = [];
  el: ElementRef;
  currentStepper: StepperUiComponent;
  private _dataStoreKey: DataStoreKey;
  get dataStoreKey(): DataStoreKey {
    return this._dataStoreKey;
  }
  set dataStoreKey(dataStoreKey: DataStoreKey) {
    if (this._dataStoreKey !== dataStoreKey && this.data.isSingleRow) {
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.selectDataChange,
        item: {
          container: null,
          dataStoreKey: dataStoreKey,
        },
      });
    }
    this._dataStoreKey = dataStoreKey;
    /*mettre à jour le datastore */
  }
  @Input()
  toggleable = true;
  @Input()
  private _collapsed = false;
  get collapsed(): boolean {
    return this._collapsed;
  }
  set collapsed(collapsed: boolean) {
    if (this.data.isSingleRow && !this.data.isReadOnly && !this.isTopEditTab) {
      collapsed = true;
    }
    if (collapsed === false) {
      // check si les enfants ont déjà été créés
      if (this.children && this.children.length > 0) {
        if (!this.childrenCreated) {
          this.buildChildren();
          this.childrenCreated = true;
          this.scrollTo();
        }
      }
    }
    this._collapsed = collapsed;
  }

  @ViewChild(PrimeFieldsetComponent, { static: true })
  fieldset: PrimeFieldsetComponent;

  ngOnInit() {
    super.ngOnInit();
    // ajout de cette edit tab aux enfants de l'editview parent
    let parentComp = this.DOMParentComponent;
    while (!(parentComp instanceof EditViewUiComponent) && !(parentComp instanceof AdditionalInfoUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    // Vérifie si c'est un addtionalinfo pour ajouter l'editTab à l'editview qui le contient
    if (parentComp instanceof AdditionalInfoUiComponent) {
      (<EditViewUiComponent>parentComp.DOMParentComponent).addTabToEditView(this);
      (<EditViewUiComponent>parentComp.DOMParentComponent).additionalInfoComponent.addTabToEditView(this);
    } else {
      // on set la datastorekey venant de l'editviewparent
      (<AbstractDynamicView>parentComp).addTabToEditView(this);
    }
    this.dataStoreKey = (<EditViewUiComponent>parentComp).currentDataStoreKey;

    // vérifie l'état de l'onglet
    if (!this.isTopEditTab && this.data && this.data.isCollapsedDefault) {
      this.collapsed = true;
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dataContainers = [];
    this.#cd = undefined;
    this.fieldset = undefined;
  }

  onChildUpdate(event: IupicsEvent) {}

  collapseTab() {
    if (!this.data.isCollapsable) {
      this.updateDisplayLogic();
    } else {
      if (this.lastCssClass === '') {
        this.lastCssClass = this.DOMParentComponent.cssClass;
        this.DOMParentComponent.cssClass = 'p-col-12 p-col-nopad';
      }
      this.isExpand = false;
    }
  }

  expandTab() {
    if (!this.data.isCollapsable) {
      this.updateDisplayLogic();
    } else if (!this.isExpand) {
      this.DOMParentComponent.cssClass = this.lastCssClass;
      this.lastCssClass = '';
      this.isExpand = true;
    }
    this.isCollapsed = false;
  }

  addDataContainerToEditTab(dataContainer: AbstractDataContainer) {
    if (dataContainer.data.columnName) {
      this.dataContainers.push(dataContainer);
      this.subscriptions.push(
        dataContainer.fieldValueModified.subscribe((dataStored) => {
          for (let i = 0; i < this.editViewParent.editTabs.length ?? 0; i++) {
            const tab = this.editViewParent.editTabs[i];
            if (i > 0) {
              this.handleTabDisplay(tab, dataStored);
            } else {
              tab.updateAllLogic(dataStored);
            }
          }
        })
      );
    }
  }

  handleTabDisplay(tab: EditTabUiComponent, dataStored: any) {
    const prevCss = tab.isDisplay;
    const isDisplayed = tab.updateDisplayLogic(dataStored);
    if (tab.children[0]) {
      if (!tab.data.isSingleRow) {
        this.handleMultiRowTab(tab, isDisplayed, prevCss, dataStored);
      } else {
        this.handleSingleRowTab(tab, isDisplayed, dataStored);
      }
    }
  }

  handleMultiRowTab(
    tab: { DOMChildrenComponent: { DOMComponent: { instance: GridViewUiComponent } }[] },
    isDisplayed: any,
    prevCss: string,
    dataStored: any
  ) {
    if (isDisplayed && prevCss === 'none' && tab.DOMChildrenComponent[0]) {
      tab.DOMChildrenComponent[0].DOMComponent.instance.GridTabInfinityScrollUiComponent.createServerDataSource(
        dataStored.data
      );
    }
  }

  handleSingleRowTab(tab: EditTabUiComponent, isDisplayed: boolean, dataStored: any) {
    if (isDisplayed && dataStored.key.tabId !== tab.tabId) {
      const mapFilter = LogicEvaluator.parseLogic(
        dataStored.data,
        tab.gridTabFilter[0],
        this.connectorService.getIupicsUserContext()
      );
      const dataStoreRequest: DataStoreRequest = this.createDataStoreRequest(tab, dataStored, mapFilter);
      this.subscriptions.push(
        this.store.getWindowSingleData(dataStoreRequest).subscribe((data) => {
          tab.updateData(data);
        })
      );
    }
  }

  createDataStoreRequest(
    tab: EditTabUiComponent,
    dataStored: { key: { windowId: any; recordId: any } },
    mapFilter: string
  ) {
    return {
      windowId: dataStored.key.windowId,
      record_id: dataStored.key.recordId,
      parent_constraint: mapFilter,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: tab.tabId,
        startRow: 0,
        endRow: 1,
        validation: tab.getTabWhereClause(),
        windowCtx: tab.getCurrentContext(),
      },
    };
  }

  checkReadAndDisplay(dataStored: DataStore) {
    super.checkReadAndDisplay(dataStored);
    this.updateReadOnlyLogic(dataStored);
  }

  updateData(dataStored: DataStore, columnName?: string) {
    this.updateAllLogic(dataStored);
    if (dataStored) {
      if (
        dataStored.status === DataStoreStatus.NEWRECORD &&
        this.data.ctxArea &&
        dataStored.data[Object.keys(this.data.ctxArea)[0]] === null
      ) {
        const request: DataStoreRequest = {
          windowId: dataStored.key.windowId,
          record_id: dataStored.key.recordId,
          parent_constraint: dataStored.key.parentId,
          compiereRequest: {
            windowType: CompiereDataGridType.WINDOW,
            entityId: dataStored.key.tabId,
            startRow: 0,
            endRow: 1,
            validation: this.getTabWhereClause(),
            windowCtx: this.getCurrentContext(),
          },
        };
        if (this.editViewParent) {
          let validation: string;
          if (this.data.validationCode) {
            validation = this.data.validationCode;
          }
          if (this.gridTabValidator && this.gridTabValidator.trim().length > 0) {
            validation = validation ? validation + ' and ' + this.gridTabValidator : this.gridTabValidator;
          }
          request.compiereRequest.validation = LogicEvaluator.parseLogic(
            this.getCurrentContext(dataStored),
            validation,
            this.connectorService.getIupicsUserContext()
          );
        }
        // check si on pointe sur la meme table que l'onglet 0
        if (
          this.data.isSingleRow &&
          this.data.tableName &&
          this.editViewParent?.linkedComponents[0]?.editViewParent?.data?.tableName === this.data.tableName
        ) {
          request.parent_constraint = '';
          request.compiereRequest.entityId = this.editViewParent.linkedComponents[0].editViewParent.tabId;
        }
        this.store.syncDataChanges(dataStored, this.data.ctxArea, true);
        this.store.getWindowSingleData(request).subscribe((dataWithCtx) => {
          this.dataStored = dataWithCtx;
          if (dataWithCtx && dataWithCtx.key && dataWithCtx.key.tabId === this.tabId) {
            for (const datacontainer of this.dataContainers) {
              if (!columnName || datacontainer.data.columnName !== columnName) {
                datacontainer.setNewData(dataWithCtx);
              }
            }
          }
        });
      } else {
        this.dataStored = dataStored;
        const istabOpenedFromAnotherTab =
          this.editViewParent &&
          this.editViewParent.linkedComponents &&
          this.editViewParent.linkedComponents[0] &&
          this.editViewParent.linkedComponents[0].data &&
          this.editViewParent.linkedComponents[0].data.isSingleRow;
        if ((dataStored.key && dataStored.key.tabId === this.tabId) || istabOpenedFromAnotherTab) {
          for (const datacontainer of this.dataContainers) {
            if (!columnName || datacontainer.data.columnName !== columnName) {
              datacontainer.setNewData(dataStored);
            }
          }
        }
      }
    }
  }

  checkForCopy(copiedData: DataStore) {
    for (const dataContainer of this.dataContainers) {
      if (!(dataContainer instanceof AccordionUiComponent) || !(dataContainer instanceof EditTabUiComponent)) {
        if (dataContainer.data.isCopy === undefined || dataContainer.data.isCopy === false) {
          copiedData.data[dataContainer.data.columnName] = dataContainer.isSwitchField
            ? 'N'
            : dataContainer.isMoneyField
              ? 0
              : null;
        }
      }
    }

    const columnNames = Object.keys(copiedData.data);
    for (const columnName of columnNames) {
      switch (columnName) {
        case 'Processed':
        case 'Processing':
        case 'IsApproved':
        case 'IsDelivered':
        case 'IsInvoiced':
        case 'IsPaid':
        case 'IsAllocated':
        case 'Posted':
        case 'I_IsImported':
        /**
         * @start_custo_code
         */
        case 'I_IsImportedYNEW':
          /**
           * @end_custo_code
           */
          copiedData.data[columnName] = 'N';
          break;
        case 'DocumentNo':
        case 'C_Location_ID':
        case 'Value':
          copiedData.data[columnName] = null;
          break;
        case 'DocStatus':
          copiedData.data[columnName] = 'DR';
          break;
        case 'DocAction':
          copiedData.data[columnName] = 'CO';
          break;
        case 'GrandTotal':
        case 'TotalLines':
        case 'TotalDr':
        case 'TotalCr':
          copiedData.data[columnName] = 0;
          break;
      }
    }
    return copiedData;
  }

  isConstraintMandatoryRespected(): string[] {
    const isOk = [];
    if (this.dataContainers) {
      let i = 0;
      while (isOk && i < this.dataContainers.length) {
        if (
          !this.dataContainers[i].isAccordion &&
          this.dataContainers[i].displayCss !== 'none' &&
          this.dataContainers[i].data.isMandatory &&
          (this.dataContainers[i].fieldValue === undefined ||
            this.dataContainers[i].fieldValue === null ||
            this.dataContainers[i].fieldValue === '')
        ) {
          isOk.push(this.dataContainers[i].label);
        }
        i++;
      }
    }
    return isOk;
  }

  isVFormatRespected(): string[] {
    const isOk = [];
    if (this.dataContainers) {
      let i = 0;
      while (isOk && i < this.dataContainers.length) {
        if (
          !this.dataContainers[i].isAccordion &&
          this.dataContainers[i].displayCss !== 'none' &&
          this.dataContainers[i].data.vFormat &&
          this.dataContainers[i].data.vFormat.length > 0 &&
          !(
            this.dataContainers[i].fieldValue === undefined ||
            this.dataContainers[i].fieldValue === null ||
            this.dataContainers[i].fieldValue === ''
          )
        ) {
          const vFormat: string = this.dataContainers[i].data.vFormat;
          const text: string = this.dataContainers[i].fieldValue;
          if (vFormat.length !== text.length) {
            isOk.push(this.dataContainers[i].label);
          } else {
            let newString = '';
            for (var j = 0; j < vFormat.length; j++) {
              const f = vFormat.charAt(j);
              const c = text.charAt(j);
              const retformat = VFormatValidator.check(f, c);
              if (!retformat.valid) {
                isOk.push(this.dataContainers[i].label);
                j = vFormat.length;
              } else {
                newString += retformat.convert;
              }
            }
            if (newString.length == text.length) {
              this.dataContainers[i].dataChange(newString);
            }
          }
        }
        i++;
      }
      this.dataContainers;
    }
    return isOk;
  }

  refreshGrid() {
    if (!this.isCollapsed && this.children[0].component === 'GridViewUiComponent' && this.DOMChildrenComponent[0]) {
      (<GridViewUiComponent>this.DOMChildrenComponent[0]).refreshGrid();
    }
  }
  onSiblingUpdate(event: IupicsEvent) {
    if (event.type === IupicsTypeEvent.collapseEvent) {
      if (this.editViewParent && this.editViewParent.editTabs && this.editViewParent.editTabs.length > 1) {
        for (const tab of this.editViewParent.editTabs) tab.collapseTab();
        // this.editViewParent.smartButtons.resizeSmartButton(event);
      }
    } else if (event.type === IupicsTypeEvent.expandEvent) {
      if (this.editViewParent && this.editViewParent.editTabs && this.editViewParent.editTabs.length > 1) {
        for (const tab of this.editViewParent.editTabs) tab.expandTab();
        // this.editViewParent.smartButtons.resizeSmartButton(event);
      }
    }
  }
  onRemoveComponent(event: IupicsEvent) {}
  ngAfterViewInit() {
    super.ngAfterViewInit();
    const request: DataStoreRequest = {
      windowId: this.dataStoreKey.windowId,
      record_id: this.dataStoreKey.recordId,
      parent_constraint: this.dataStoreKey.parentId,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.dataStoreKey.tabId,
        startRow: 0,
        endRow: 1,
        validation: this.getTabWhereClause(),
        windowCtx: this.getCurrentContext(),
      },
    };
    this.subscriptions.push(
      this.store.getWindowSingleData(request).subscribe((dataStored) => {
        this.dataStored = dataStored;
        if (this.children[0].component === 'SelectOrderComponent') {
          this.children[0].data.recordID = this.dataStored.key.recordId;
        }
        // check si le tab doit etre affiché ou non
        this.updateAllLogic(dataStored);
        // check si il faut déjà créer les enfants
        if (!this.collapsed) {
          this.buildChildren();
          this.childrenCreated = true;
        } else {
          if (this.isEditTabShouldOpen() && (!this.data.isSingleRow || this.isReadOnly)) {
            this.collapsed = false;
          } else {
            this.isExpand = false;
          }
        }
        if (
          this.data.isSingleRow &&
          this.container &&
          this.container.activeTab &&
          this.container.activeTab.othersRecordId &&
          this.container.activeTab.othersRecordId.length > 0
        ) {
          if (this.container.activeTab.othersRecordId[0].tabId == this.tabId) {
            this.openInNewBlade();
            this.container.activeTab.othersRecordId.splice(0, 1);
            if (this.container.activeTab.othersRecordId.length === 0) {
              this.container.activeTab.othersRecordId = undefined;
            }
          }
        }
      })
    );
  }
  isEditTabShouldOpen() {
    let shouldOpenForZoomOrUrl = false;
    if (this.editViewParent.zoomInfo && this.editViewParent.zoomInfo.children) {
      const searchedElement = this.editViewParent.zoomInfo.children[this.editViewParent.zoomInfo.children.length - 1];
      if (searchedElement && this.children && this.children.length > 0 && this.children[0].data) {
        if (searchedElement.Tab_ID == this.children[0].data.AD_Tab_ID) {
          shouldOpenForZoomOrUrl = true;
        }
      }
    }
    if (this.container.activeTab.othersRecordId && this.container.activeTab.othersRecordId.length > 0) {
      const searchedElement = this.container.activeTab.othersRecordId[0];
      if (searchedElement && this.children && this.children.length > 0 && this.children[0].data) {
        if (searchedElement.tabId == this.children[0].data.AD_Tab_ID) {
          shouldOpenForZoomOrUrl = true;
        }
      }
    }
    return shouldOpenForZoomOrUrl;
  }

  buildChildren() {
    /*pour permettre l'affichage instantané des rows */
    if (this.gridTabFilter && this.data.isSingleRow) {
      const mapfilter = LogicEvaluator.parseLogic(
        this.dataStored.data,
        this.gridTabFilter[0],
        this.connectorService.getIupicsUserContext()
      );
      const dataStoreRequest: DataStoreRequest = {
        windowId: this.dataStored.key.windowId,
        record_id: this.dataStored.key.recordId,
        parent_constraint: mapfilter,
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: this.tabId,
          startRow: 0,
          endRow: 1,
          validation: this.getTabWhereClause(),
          windowCtx: this.getCurrentContext(),
        },
      };
      // check si on pointe sur la meme table que l'onglet 0
      if (
        this.data.isSingleRow &&
        this.data.tableName &&
        this.editViewParent?.linkedComponents[0]?.editViewParent?.data?.tableName === this.data.tableName
      ) {
        dataStoreRequest.parent_constraint = '';
        dataStoreRequest.compiereRequest.entityId = this.editViewParent.linkedComponents[0].editViewParent.tabId;
      }
      this.subscriptions.push(
        this.store.getWindowSingleData(dataStoreRequest).subscribe((data) => {
          this.dataStored = data;
          for (const child of this.children) {
            child.parentTab = this;
            if (child.data !== undefined) {
              child.data.isTabTopLevel = false; // pour dire que les enfants n'active pas les keybind
            }
            WindowFactoryUtils.addContainerComponent({
              parent: this,
              item: child,
              isCssOnComponent: child.container ? child.isCssOnComponent : false,
            });
          }
          if (this.isTopEditTab) {
            this.#cd.detectChanges();
          }
        })
      );
    } else {
      for (const child of this.children) {
        child.parentTab = this;
        if (child.data !== undefined) {
          child.data.isTabTopLevel = false; // pour dire que les enfants n'active pas les keybind
        }

        if (child.data?.AD_Form_ID) {
          this.addSpecificContainerComponent(child);
        } else {
          WindowFactoryUtils.addContainerComponent({
            parent: this,
            item: child,
            isCssOnComponent: child.container ? child.isCssOnComponent : false,
          });
        }
      }
      if (this.isTopEditTab) {
        this.#cd.detectChanges();
      }
    }
  }

  private addSpecificContainerComponent(child: DynamicComponent) {
    this.uiCreatorService.getSpecificWindow(child.data.AD_Form_ID).subscribe(async (specificWindow) => {
      const specificCompRef = createComponent<SpecificWindowUiComponent>(this.vcr, specificWindow.angularClass);
      const specificCompInstance = specificCompRef.instance;
      specificCompInstance.container = this.container;
      specificCompInstance.componentRef = specificCompRef;
      specificCompInstance.id = this.container.activeTab.id;
      specificCompInstance.formId = specificWindow.AD_Form_ID;
      specificCompInstance.vcrwindow = this.vcr;
      specificCompInstance.index = this.vcr.length - 1;
      specificCompInstance.activeTab = this.container.activeTab;
      specificCompInstance.parentTab = this;
      specificCompInstance.parentStore = this.dataStored;
      this.DOMChildrenComponent.push(specificCompInstance);
      this.componentRefs.push(specificCompRef);
    });
  }

  openInNewBlade() {
    const linkedComponent = this;
    const item: DynamicComponent = {
      container: this.container,
      DOMParentComponent: this.container,
      linkedComponents: [linkedComponent],
      component: 'EditViewUiComponent',
      cssClass: 'iupics-blade-content',
      isCssOnComponent: false,
      tabId: this.tabId,
      windowId: this.dataStoreKey.windowId,
    };
    this.componentEmitter.emit({
      type: IupicsTypeEvent.showEditView,
      item: item,
    });

    this.notifierLinkedComponent.next({
      type: IupicsTypeEvent.selectDataChange,
      item: {
        container: null,
        dataStoreKey: this.dataStoreKey,
      },
    });
  }
  scrollTo() {
    if (this.editViewParent) {
      this.editViewParent.goToAnchor(new MouseEvent('click'), this);
    }
  }
  /**
   * mise à jour du display
   * @param dataStore nouveau datastore à prendre en compte
   */
  updateDisplayLogic(dataStore?: DataStore) {
    let isDisplayed = true;
    let displayCss: 'inline' | 'none' = 'inline';
    if (!this.isTopEditTab) {
      let displayLogic: string;
      if (
        this.children &&
        this.children[0].component === 'GridViewUiComponent' &&
        this.data &&
        !this.data.isSingleRow
      ) {
        // displayLogic de la grille
        displayLogic = this.children[0].data.displayLogic;
        if (this.DOMChildrenComponent.length > 0) {
          (<GridViewUiComponent>this.DOMChildrenComponent[0]).updateAllLogic();
        }
      } else {
        // displayLogic du tab
        displayLogic = this.data ? this.data.displayLogic : null;
      }
      if (displayLogic) {
        const currentContext = this.getCurrentContext(dataStore ? dataStore : this.dataStored);
        isDisplayed = LogicEvaluator.evaluateLogic(currentContext ? currentContext : null, displayLogic);
      }
      displayCss = isDisplayed ? 'inline' : 'none';
    } else {
      displayCss = 'inline';
    }
    if (this.isDisplay !== displayCss) {
      this.isDisplay = displayCss;
    }
    return isDisplayed;
  }
  /**
   * mise à jour du readOnly
   * @param dataStore nouveau datastore à prendre en compte
   */
  updateReadOnlyLogic(dataStore?: DataStore) {
    const readOnlyLogic = this.data.readOnlyLogic;
    if (this.data.isReadOnly) {
      this.isReadOnly = true;
    } else if (readOnlyLogic) {
      if (EditViewUtils.checkLogic(readOnlyLogic, this.getCurrentContext(dataStore ? dataStore : this.dataStored))) {
        this.isReadOnly = true;
      } else {
        this.isReadOnly = false;
      }
      if (this.children[0] && this.children[0].component === 'GridViewUiComponent' && this.DOMChildrenComponent[0]) {
        this.DOMChildrenComponent[0].isReadOnly = this.isReadOnly;
      }
    }
    if (this.isTopEditTab && this.editViewParent) {
      this.editViewParent.isReadOnly = this.isReadOnly;
    }
    return this.isReadOnly;
  }
  /**
   * mise à jour du IsDeletable
   * @param dataStore nouveau datastore à prendre en compte
   */
  updateIsDeleteableOnlyLogic(dataStore?: DataStore) {
    const isDeletableLogic = this.data.isDeletableLogic;
    if (!isDeletableLogic || (!this.children?.length ?? 0) === 0) {
      return;
    }

    this.isDeleteable = EditViewUtils.checkLogic(
      isDeletableLogic,
      this.getCurrentContext(dataStore ?? this.dataStored)
    );
    if (this.children[0] && this.children[0].component === 'GridViewUiComponent' && this.DOMChildrenComponent[0]) {
      this.DOMChildrenComponent[0].isDeleteable = this.isDeleteable ?? true;
    }

    if (this.isTopEditTab && this.editViewParent?.isDeleteable) {
      this.editViewParent.isDeleteable = this.isDeleteable ?? true;
    }
  }

  updateAllLogic(datastore?: DataStore) {
    if (!this.data.isSingleRow) {
      this.dataStored = datastore;
    }
    this.updateDisplayLogic(datastore);
    this.updateReadOnlyLogic(datastore);
    this.updateIsDeleteableOnlyLogic(datastore);
  }

  getTabWhereClause() {
    let validation: string;
    if (this.gridTabValidator && this.gridTabValidator.trim().length > 0) {
      validation = this.gridTabValidator;
    }
    validation = LogicEvaluator.replaceVariables(
      validation,
      this.connectorService.getIupicsUserContext(),
      this.getCurrentContext()
    );
    return validation;
  }
}
