import { NgClass, NgStyle } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  CompiereDataGridFilterModel,
  CompiereDataGridFilterType,
  CompiereDataGridRequestJSON,
  CompiereDataGridType,
  DataStore,
  DataStoreKey,
  DataStoreRequest,
  DataStoreStatus,
} from '@compiere-ws/models/compiere-data-json';
import { RecentItem } from '@compiere-ws/models/compiere-recent-item';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { ViewType } from '@iupics-components/models/view-type.enum';
import CardsUiComponent from '@iupics-components/specific/window/customer-360/components/cards/cards-ui/cards-ui.component';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import SpecificWindowUiComponent, {
  SpecificGridQuery,
} from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import GridTabInfinityScrollUiComponent, {
  GridTabInfinityScrollHeadlessUiComponent,
} from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import BladeUiComponent from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import MenuBarUiComponent from '@iupics-components/standard/menu/menu-bar-ui/menu-bar-ui.component';
import { GridViewGetFormIDByTabID } from '@iupics-components/standard/menu/utils/menu.utils';
import { AppConfig } from '@iupics-config/app.config';
import { CacheManagerService } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { KeybindStateManagerService } from '@iupics-manager/managers/keybind-state-manager/keybind-state-manager.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { RecentItemsManagerService } from '@iupics-manager/managers/recent-items-manager/recent-items-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { DynamicComponent } from '@iupics-manager/models/dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsEvent, IupicsTypeEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import {
  ApizGridEvent,
  AppliedItem,
  FilterType,
  GridOptionsAppliedItems,
  injectColumnApiService,
  injectGridApiService,
  provideColumnApiService,
  provideGridApiService,
  providePanelService,
  RowSelectionMode,
} from '@iupics/apiz-grid';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { DomHandler } from 'primeng/dom';
import { ProgressBarModule } from 'primeng/progressbar';
import { ObjectUtils } from 'primeng/utils';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import ExportDataUiComponent from '../../../specific/window/export-data-ui/export-data-ui.component';
import ProcessUiComponent from '../../../specific/window/process-ui/process-ui.component';
import CalendarViewUiComponent from '../calendar/calendar-view-ui/calendar-view-ui.component';
import ChartUiComponent from '../chart-ui/chart-ui.component';
import { injectFilterCtxService, provideFilterCtxService } from '../filters/services/filter-ctx.service';
import KanbanUiComponent from '../kanban/kanban-ui/kanban-ui.component';
import { provideViewColumnsService } from '../services/view-columns.service';
import TreeUiComponent from '../tree-ui/tree-ui.component';

@Component({
  selector: 'iu-grid-view-ui',
  templateUrl: './grid-view-ui.component.html',
  styleUrls: ['./grid-view-ui.component.scss'],
  animations: [...Global.overlayAnimationAutocomplete, ...Global.overlayAnimationCalendar],
  standalone: true,
  imports: [
    NgClass,
    MenuBarUiComponent,
    ProgressBarModule,
    TreeUiComponent,
    GridTabInfinityScrollHeadlessUiComponent,
    NgStyle,
    KanbanUiComponent,
    CalendarViewUiComponent,
    ChartUiComponent,
    CardsUiComponent,
    forwardRef(() => ModalUiComponent),
    ExportDataUiComponent,
    ProcessUiComponent,
  ],
  providers: [
    provideGridApiService(), // Provide the grid API service
    provideColumnApiService(), // Provide the column API service
    providePanelService(), // Provide the panel service
    forwardRef(() => provideFilterCtxService()), // Provide the filterCtx service at this level and not root level
    provideViewColumnsService(), // Provide the tabColumns service at this level and not root level
  ],
})
export default class GridViewUiComponent extends AbstractDynamicComponent implements OnInit, AfterViewInit, OnDestroy {
  dataStoreService = inject(DataStoreService);
  translateService = inject(TranslateService);
  riManager = inject(RecentItemsManagerService);
  keybindStateManager = inject(KeybindStateManagerService);
  messageManager = inject(MessageManagerService);
  connectorService = inject(SecurityManagerService);
  uiCreatorService = inject(UICreatorService);
  config = inject(AppConfig);
  elementRef = inject(ElementRef);

  @Input() gridTitle: string;
  @Input() isSpecificGrid = false;
  @Input() isSpecificGridTitleDisplay = true;
  @Input() rowSelection: RowSelectionMode = 'multiple';
  @Input() hasUniversalFilter = true;
  @Input() fromForm = false;
  @Input() hasCheckbox = true;
  public isGridCollapsed = false;

  parentFormID: number;
  @ViewChild(MenuBarUiComponent)
  menuBarUiComponent: MenuBarUiComponent;
  @ViewChild(GridTabInfinityScrollHeadlessUiComponent)
  GridTabInfinityScrollUiComponent: GridTabInfinityScrollHeadlessUiComponent;
  @ViewChild(CalendarViewUiComponent)
  calendarViewUiComponent: CalendarViewUiComponent;
  @ViewChild(KanbanUiComponent)
  kanbanUiComponent: KanbanUiComponent;
  @ViewChild(ChartUiComponent)
  chartUiComponent: ChartUiComponent;
  @ViewChild(TreeUiComponent)
  treeUiComponent: TreeUiComponent;
  @ViewChild(CardsUiComponent)
  cardsUiComponent: CardsUiComponent;
  @ViewChild('panelCalendar')
  panelCalendar: ElementRef;
  @ViewChild('exportDataModal')
  exportDataModal: ModalUiComponent;
  displayProcessUI = false;
  displayFormUI = false;
  processId: number;
  formId: number;
  initFromSwitch = false;
  isTree: boolean;
  isCards: boolean;
  isDisplayed = true;
  whereClause = '';
  visibleButtons = true;
  specificWindowTitle: string;
  /**
   * permet de savoir si il faut lancer une recherche au démarrage de la fenetre
   */
  isLaunchSearchGrid = true;
  editViewParent: any;
  columnNames;

  @Output() gridCellEditingStopped = new EventEmitter<any>();
  @Output() gridViewCellClicked = new EventEmitter<any>();
  @Output() multiSelectEmitter = new EventEmitter<{ selected: boolean; data: {}[] }>();
  @Output() gridRefreshed = new EventEmitter<any>();
  @Output() gridPrefLoaded = new EventEmitter<GridTabInfinityScrollUiComponent>();
  @Output() gridTabAfterViewInitEmitter = new EventEmitter<any>();

  @ViewChild('divContent') divContent: ElementRef;
  @ViewChild('vcrSpecific', { read: ViewContainerRef, static: true }) vcrSpecific: ViewContainerRef;

  /**
   * @start_custo_code
   */
  transportManagementWindowID = this.config.getConstant('GridViewUiComponent#TransportManagementWindow_ID');
  /**
   * @end_custo_code
   */

  @Input() windowType = IupicsMenuType.WINDOW;

  _viewType: ViewType = undefined;
  set viewType(value: any) {
    this._viewType = value;
    // notify
    if (!(this.DOMParentComponent.DOMParentComponent instanceof EditTabUiComponent)) {
      (<BladeUiComponent>this.DOMParentComponent).notifyUrlChange();
    }
  }

  get viewType() {
    return this._viewType;
  }

  oldWidth: number;
  private _selectedRecordId;
  set selectedRecordId(value: any) {
    const isDiff = this._selectedRecordId !== value;
    this._selectedRecordId = value;
    // notify
    if (isDiff && !(this.DOMParentComponent instanceof EditTabUiComponent)) {
      (<BladeUiComponent>this.DOMParentComponent).notifyUrlChange();
    }
  }

  get selectedRecordId() {
    return this._selectedRecordId;
  }

  @Input() isMenuDisplay = true;
  exportDataOptions: any;
  displayExportDataModal = false;

  isTabTopLevel = true;
  isSplitView = false;
  exportIds: any[];

  noColumnToDisplay = false;

  request: CompiereDataGridRequestJSON;

  overlayVisible = false;
  label = '';
  isLabelDisplay = false;
  scrollHeight;
  suggestions;
  field;
  ObjectUtils = ObjectUtils;
  itemTemplate;
  noResults;
  emptyMessage;
  highlightOption;

  panelStyleClass;
  panelStyle;
  inline;
  documentClickListener: () => void;
  @Input() suppressRowClickSelection = false;

  modalClass = 'p-col-10 p-md-6 p-lg-4';
  // * searchPanel pour universal filter
  //   displaySearch: boolean;
  searchLinkedComponent: any;
  // *
  isLoading = false;

  infoWindowId?: number;
  searchPanelValidation: string;

  isChangelog = false;

  @Input() isZoomTargetGrid = false;

  api = injectGridApiService();
  columnApi = injectColumnApiService();
  filterCtxService = injectFilterCtxService();
  #filters: AppliedItem<'filter'>[] = [];

  ngOnInit() {
    Global.startPerf(this);
    this.vcr = this.DOMParentComponent.vcr;
    this.editViewParent = this.DOMParentComponent;
    if (this.editViewParent !== this.container) {
      while (!(this.editViewParent instanceof EditViewUiComponent)) {
        this.editViewParent = this.editViewParent.DOMParentComponent;
      }
    } else {
      this.editViewParent = undefined;
    }
    this.isTree = this.data.hasTree && this.data.urlTree !== undefined && this.data.urlTree !== null;
    this.isCards = this.data?.tabType !== undefined && typeof this.data?.tabType === 'string';
    if (this.isCards) {
      this.viewType = ViewType.CARDS;
    }

    if (this.data?.isTabTopLevel !== undefined) {
      this.isTabTopLevel = this.data.isTabTopLevel;
    }

    this.subscriptions.push(
      this.keybindStateManager.splitViewEmitter.subscribe((value: boolean) => (this.isSplitView = value))
    );

    if (this.container?.activeTab?.othersRecordId?.[0]?.tabId == this.tabId) {
      if (this.container.activeTab.othersRecordId[0].recordId === 'newRecord') {
        this.openNew();
      } else {
        const filterModel = this.createFilterModelWithRecordId(this.container.activeTab.othersRecordId[0].recordId);
        this.initRequest = {
          filterModel,
          validation: this.getTabWhereclause(),
          windowCtx: this.getCurrentContext(),
        };
      }
    }
    if (
      this.data.columnsTableHeader.length === 0 &&
      (!this.data.searchColumns || this.data.searchColumns.length === 0)
    ) {
      this.noColumnToDisplay = true;
    }
    this.visibleButtons = this.windowType === IupicsMenuType.WINDOW;

    this.filterCtxService.registerGrid(this);
    this.api.addEventListener('appliedItemsUpdated', this.#appliedItemsUpdatedHandler.bind(this));
  }

  #appliedItemsUpdatedHandler(e: ApizGridEvent<GridOptionsAppliedItems>) {
    const previousFilters = [...this.#filters];
    const currentFilters = [...(e.data?.filters ?? [])];
    this.filterCtxService.reinitLocalDatastore();

    for (const filter of previousFilters) {
      const found = currentFilters.find((cf) => cf.colId === filter.colId);
      if (found === undefined) {
        // filtre précédent non présent dans les courants
        filter.values = filter.filterType === FilterType.SET ? [[]] : [];
        currentFilters.push(filter);
        delete this.GridTabInfinityScrollUiComponent?.query?.filters?.[filter.colId];
      }
    }

    this.filterCtxService.updateLocalDatastore(currentFilters);
    this.#filters = currentFilters.filter((cf) => cf.values.filter((v) => v !== undefined).length);
  }

  ngAfterViewInit() {
    // We need to set the viewType to GRID if the tabType is not set, otherwise the grid will not be displayed
    this.viewType ||= ViewType.GRID;
    this.updateAllLogic();
    Global.endPerf(this, '');
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.filterCtxService.unregisterGrid(this);
    this.api.ngOnDestroy();
    this.elementRef = undefined;
    this.vcrSpecific?.clear();
    this.vcrSpecific = undefined;
    this.divContent = undefined;
  }

  createFilterModelWithRecordId(recordId: string) {
    const filterModel = {};
    const splittedRecord = recordId.split(',');
    if (splittedRecord.length > 1) {
      for (let i = 0; i < splittedRecord.length; i += 2) {
        let filterType = null;
        if (splittedRecord[i + 1]) {
          if (!splittedRecord[i].includes('_ID') && this.tabId) {
            filterType = this.dataStoreService.getColumnCompiereDataGridFilterType(this.tabId, splittedRecord[i]);
          }
          const filter: CompiereDataGridFilterModel = {
            filterType: filterType ?? CompiereDataGridFilterType.NUMBER,
            operators: [OperatorFilterType.EQUALS],
            values: [[splittedRecord[i + 1]]],
          };
          filterModel[splittedRecord[i]] = filter;
        }
      }
      return filterModel;
    }
  }

  openRemainingEditView() {
    /* Continuation du zoomAcross */
    if (this.editViewParent?.zoomInfo?.children) {
      this.zoomAcrossPropagation(this.editViewParent.zoomInfo);
    }
    /*divers edit à ouvrir*/
    if (this.container?.activeTab?.othersRecordId?.length > 0) {
      this.openOtherEditViews();
    }
  }

  forceOpenEditView(record_ID, zoomInfo?) {
    const dataStoreRequest: DataStoreRequest = {
      windowId: this.data.AD_Window_ID,
      record_id: record_ID,
      parent_constraint: this.parentTab ? this.parentTab.dataStoreKey.recordId : undefined,
      compiereRequest: {
        windowType: CompiereDataGridType.WINDOW,
        entityId: this.tabId,
        startRow: 0,
        endRow: 1,
        validation: this.getTabWhereclause(),
        windowCtx: this.getCurrentContext(),
      },
    };
    if (
      dataStoreRequest.parent_constraint !== null &&
      dataStoreRequest.parent_constraint !== undefined &&
      dataStoreRequest.parent_constraint.length > 0
    ) {
      let filtersArray = dataStoreRequest.parent_constraint.split('=');
      if (filtersArray.length <= 1) {
        const splittedRecord = dataStoreRequest.parent_constraint.split(',');
        filtersArray = splittedRecord.length > 1 ? splittedRecord : [];
      }
      if (filtersArray.length == 2) {
        if (
          !this.dataStoreService.getColumnCompiereDataGridFilterType(
            dataStoreRequest.compiereRequest.entityId,
            filtersArray[0]
          )
        ) {
          dataStoreRequest.parent_constraint = '';
        }
      }
    }

    this.subscriptions.push(
      this.dataStoreService.getWindowSingleData(dataStoreRequest).subscribe((data) => {
        if (data) {
          const parentComp = this.getFirstEditViewParent(this.DOMParentComponent);
          if (parentComp) {
            parentComp.gridTabIdSelected = this.tabId;
          }
          this.openEditView(zoomInfo);
          if (this.isTabTopLevel) {
            const ri = new RecentItem(
              `${this.data.label}: ${this.riManager.getInfoLabel(data.data)}`,
              data.key.tabId,
              data.key.windowId,
              data.data.Data_UUID
            );
            this.riManager.pushRecentItem(ri).subscribe();
          }
          this.updateData(data.key);
        }
      })
    );
  }

  onRowSelectedOnGridTab(dataStoreKey: DataStoreKey) {
    this.gridViewCellClicked.emit(dataStoreKey); // smart button
    if (this.checkEditViewCreated()) {
      const parentComp = this.getFirstEditViewParent(this.DOMParentComponent);
      if (parentComp) {
        parentComp.gridTabIdSelected = this.tabId;
      }
    }
    this.openEditView();
    if (this.isTabTopLevel) {
      const request: DataStoreRequest = {
        windowId: (<BladeUiComponent>this.container).infoComponent.windowId,
        record_id: dataStoreKey.recordId,
        parent_constraint: '',
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: this.tabId,
          startRow: 0,
          endRow: 1,
          validation: this.getTabWhereclause(),
          windowCtx: this.getCurrentContext(),
        },
      };
      this.subscriptions.push(this.riManager.addRecentItem(request, this.data.label).subscribe());
    }
    this.updateData(dataStoreKey);
  }

  remove(event: MouseEvent): void {
    const dataUUIDs = this.dataStoreService.getDataUUIDFromTabID(this.data.AD_Tab_ID);
    const recordIds = [];
    const selectedNodes = this.GridTabInfinityScrollUiComponent.grid.api.getSelectedNodes();
    for (const node of selectedNodes) {
      const recordId = {};
      for (const dataUUID of dataUUIDs) {
        const data = node.getRowData()[dataUUID];
        if (data instanceof Object) {
          recordId[dataUUID] = data.id;
        } else {
          recordId[dataUUID] = data;
        }
      }
      recordIds.push(recordId);
    }
    const dataStoreKey: DataStoreKey = {
      windowId: this.data.AD_Window_ID,
      tabId: this.data.AD_Tab_ID,
      recordId: '',
      parentId: this.editViewParent
        ? this.editViewParent.currentDataStoreKey
          ? this.editViewParent.currentDataStoreKey.recordId
          : ''
        : '',
    };
    this.isLoading = true;
    this.dataStoreService
      .deleteWindowData(dataStoreKey, recordIds)
      .pipe(
        switchMap((res) =>
          res.success > 0 ? this.riManager.deleteRecentItems(dataStoreKey, recordIds).pipe(map(() => res)) : of(res)
        )
      )
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            if (this.GridTabInfinityScrollUiComponent) {
              this.GridTabInfinityScrollUiComponent.grid.api.deselectAll();
              this.refreshGridAndParent();
            }
            this.messageManager.newMessage(
              new IupicsMessage(
                this.translateService.instant('gridView.deleteMessageTitle'),
                this.translateService.instant('gridView.deletedLines') +
                ' : ' +
                JSON.stringify(res.success) +
                (res.errors.length === 0
                  ? ''
                  : ' / ' + this.translateService.instant('gridView.errors') + ' : ' + res.errors.length),
                res.errors.length === 0 ? 'success' : res.success > 0 ? 'warning' : 'error'
              )
            );
          } else {
            throw new Error(this.translateService.instant('gridView.deleteErrorMessage'));
          }
        },
        error: (error) => {
          this.isLoading = false;
          throw new Error(error?.error?.message ?? error.message);
        },
      });
  }

  openNew() {
    if (!this.checkAndExecuteOverride('new')) {
      if (this.editViewParent === null || this.editViewParent === undefined) {
        this.DOMParentComponent.notifyUrlChange('newRecord');
      }
      if (this.GridTabInfinityScrollUiComponent) {
        if (!this.GridTabInfinityScrollUiComponent.isTabTopLevel) {
          const parentComp = this.getFirstEditViewParent(this.DOMParentComponent);
          if (parentComp) {
            if (!this.dataStoreService.checkDataBeforeNewLine(parentComp.currentDataStoreKey)) {
              parentComp.beforeSave(null).then((_) => {
                parentComp.gridTabIdSelected = this.tabId;
                this.openEditView();
                this.updateData(
                  this.dataStoreService.newWindowData(
                    this.container.infoComponent.windowId,
                    this.tabId,
                    null,
                    EditViewUtils.getParentDatastoreKeyFromGrid(this)
                  ).key
                );
              });
            } else {
              this.openEditView();
              this.updateData(
                this.dataStoreService.newWindowData(
                  this.container.infoComponent.windowId,
                  this.tabId,
                  null,
                  EditViewUtils.getParentDatastoreKeyFromGrid(this)
                ).key
              );
            }
            // }
          }
        } else {
          if (this.checkEditViewCreated()) {
            const parentComp = this.getFirstEditViewParent(this.DOMParentComponent);
            if (parentComp) {
              parentComp.gridTabIdSelected = this.tabId;
            }
          }
          this.openEditView();
          this.updateData(
            this.dataStoreService.newWindowData(
              this.container.infoComponent.windowId,
              this.tabId,
              null,
              EditViewUtils.getParentDatastoreKeyFromGrid(this)
            ).key
          );
        }
      }
    }
  }

  /*
   * emailEditor: { display: { key: 'displayEmailEditor', value: displayEmailEditor }}
   * joinFilesPanel: { display: { key: 'displayJoinFilesPanel', value: displayJoinFilesPanel }}
   * processModal: { display: { key: 'displayProcessUI', value: displayProcessUI }, id: { key: 'processId', value: processId } }
   * formModal: { display: { key: 'displayFormUI', value: displayFormUI }, id: { key: 'formId', value: formId } }
   */
  updateModalDisplay(display: { key: string; value: boolean }, id?: { key: string; value: number }) {
    this.displayModal(display, id);
  }

  displayModal(display: { key: string; value: boolean }, id?: { key: string; value: number }) {
    this[display.key] = display.value;
    if (id && display.value === true) {
      this[id.key] = id.value;
      if (id.key !== 'processId') {
        this.createSpecificWindow(id.value);
      }
    }
  }

  createSpecificWindow(formId: number) {
    this.subscriptions.push(
      this.uiCreatorService.getSpecificWindow(formId).subscribe((specificWindow) => {
        let component;
        if (
          specificWindow.angularClass &&
          specificWindow.angularClass.length > 0 &&
          specificWindow.angularClass !== 'default'
        ) {
          component = CacheManagerService.iupics_specific_window.get(specificWindow.angularClass);
        }
        if (!component) {
          component = CacheManagerService.iupics_specific_window.get('default');
        }
        this.vcrSpecific.clear();
        const componentRef = this.vcrSpecific.createComponent<SpecificWindowUiComponent>(component);
        this.specificWindowTitle = specificWindow.name;
        componentRef.instance.name = specificWindow.name;
        componentRef.instance.title = specificWindow.title;
        componentRef.instance.description = specificWindow.description;
        componentRef.instance.help = specificWindow.help;
        // componentRef.instance.iconClass = specificWindow.iconClass;
        componentRef.instance.componentRef = componentRef;
        // componentRef.instance.id = specificWindow.id;
        componentRef.instance.isModal = true;
        componentRef.instance.formId = formId;
        componentRef.instance.vcrwindow = this.vcrSpecific;
        componentRef.instance.parentComponent = this;
        componentRef.instance.index = this.vcrSpecific.length - 1;
        componentRef.instance.closeModalEmitter.subscribe({
          next: () => this.updateModalDisplay({ key: 'displayFormUI', value: false }),
        });
        this.componentRefs.push(componentRef);
      })
    );
  }

  getFirstEditViewParent(component): EditViewUiComponent {
    // find out if the row is from the main gridview or not
    let parentComp = component;
    while (parentComp && !(parentComp instanceof EditViewUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    return <EditViewUiComponent>parentComp;
  }

  deleteLinkedEditView() {
    const editViewParent = this.getFirstEditViewParent(this.DOMParentComponent);
    if (editViewParent) {
      editViewParent.gridTabIdSelected = null;
    }
  }

  checkEditViewCreated(): boolean {
    const parentComp = this.getFirstEditViewParent(this.DOMParentComponent);
    // check if edit view has already been opened earlier
    return parentComp
      ? parentComp.gridTabIdSelected !== null && parentComp.gridTabIdSelected !== undefined
      : this.isEditExist();
  }

  openEditView(zoomInfo?) {
    if (!this.isEditExist()) {
      const linkedComponent = this;
      const item: DynamicComponent = {
        container: this.container,
        DOMParentComponent: this.container,
        linkedComponents: [linkedComponent],
        component: 'EditViewUiComponent',
        cssClass: `iupics-blade-content ${this.data?.AD_Form_ID ? 'iupics-specific-blade-content' : ''}`,
        isCssOnComponent: false,
        tabId: this.tabId,
        windowId: this.data.AD_Window_ID,
        zoomInfo: zoomInfo,
        parentStore: this.editViewParent ? this.editViewParent.editTabs[0].dataStored : null,
      };
      let i = 1;
      let find = false;
      while (i < this.container.DOMChildrenComponent.length && !find) {
        if (
          this.container.DOMChildrenComponent[i] === this.editViewParent &&
          ++i < this.container.DOMChildrenComponent.length
        ) {
          (<EditViewUiComponent>this.container.DOMChildrenComponent[i]).removeComponent();
          find = true;
        } else {
          i++;
        }
      }
      this.componentEmitter.emit({
        type: IupicsTypeEvent.showEditView,
        item: item,
      });
    } else {
      // check si on doit agrandir l'editview existante
      if (this.container?.breadcrumbComponent) {
        const breadCrumb = (<BladeUiComponent>this.container).breadcrumbComponent;
        if (breadCrumb) {
          const currentTabId = this.editViewParent ? this.editViewParent.tabId : 0;
          const index = breadCrumb.model.findIndex((item) => parseInt(item.id, 10) === currentTabId && !item.disabled);
          if (index > -1 && breadCrumb.model.length > index + 1) {
            breadCrumb.itemClick(null, breadCrumb.model[index + 1]);
          }
        }
      }
    }
  }

  updateData(dataStoreKey: DataStoreKey, fromNew = false) {
    if (fromNew) {
      this.notifierLinkedComponent.next({
        type: IupicsTypeEvent.selectDataChange,
        item: {
          container: null,
          dataStoreKey: dataStoreKey,
        },
      });
    } else {
      // si une edit view n'est pas encore créer on n'appelle pas de popup
      this.notifierLinkedComponent.next({
        type: this.isEditExist() ? IupicsTypeEvent.checkBeforeChange : IupicsTypeEvent.selectDataChange,
        item: {
          container: null,
          dataStoreKey: dataStoreKey,
        },
      });
    }
  }

  checkBeforeChange(editView: EditViewUiComponent, dataStoreKey: DataStoreKey) {
    if (editView.checkData()) {
      if (!editView.transmitDataChange(dataStoreKey, this.tabId)) {
        this.onRowSelectedOnGridTab(dataStoreKey);
      }

      return;
    }

    const ctx = this.connectorService.getIupicsUserContext();
    if (ctx['AutoCommit'] === 'Y') {
      editView.beforeSave(null);
      return;
    }

    Global.infoDialog.message = {
      summary: this.translateService.instant('infodialog.dialogs.checkBefore.close.title'),
      detail: this.translateService.instant('infodialog.dialogs.checkBefore.close.message'),
    };
    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
    Global.infoDialog.showInfoDialog();

    const confirm = Global.infoDialog.confirm.subscribe({
      next: () => {
        editView.beforeSave(null);
        if (
          editView.conflictsResult &&
          editView.conflictsResult.hasConflicts === false &&
          editView.conflictsResult.mustRefresh === false
        ) {
          if (!editView.transmitDataChange(dataStoreKey, this.tabId)) {
            this.onRowSelectedOnGridTab(dataStoreKey);
          }
        }
        confirm.unsubscribe();
        cancel.unsubscribe();
      },
    });

    const cancel = Global.infoDialog.cancel.subscribe({
      next: () => {
        if (editView.editTabs[0].dataStored.status !== DataStoreStatus.NEWRECORD) {
          this.subscriptions.push(
            this.dataStoreService.syncWithRemoteWindowData(editView.currentDataStoreKey).subscribe()
          );
        } else {
          this.dataStoreService.deleteDataFromStoreOnly(editView.currentDataStoreKey);
        }
        if (!editView.transmitDataChange(dataStoreKey, this.tabId)) {
          this.onRowSelectedOnGridTab(dataStoreKey);
        }
        confirm.unsubscribe();
        cancel.unsubscribe();
      },
    });
  }

  onChildUpdate(event): void {
    // resize grid when its opened
    if (event.type === IupicsTypeEvent.showGridView && this.GridTabInfinityScrollUiComponent && !this.isGridCollapsed) {
      this.GridTabInfinityScrollUiComponent.refresh();
    }
  }

  onSiblingUpdate(event: IupicsEvent) {
    switch (event.type) {
      case IupicsTypeEvent.collapseEvent:
        this.rowSelection = 'single';
        this.menuBarUiComponent.setVisibleButton(false);
        this.isGridCollapsed = true;
        if (!Global.isMobile()) {
          this.oldWidth = this.oldWidth ?? this.divContent.nativeElement.clientWidth;
          if (this.GridTabInfinityScrollUiComponent) {
            this.isTree = false;
            this.GridTabInfinityScrollUiComponent.onlyOneColumn();
          }
          if (this.calendarViewUiComponent) {
            setTimeout(() => {
              this.calendarViewUiComponent.calendar.updateSize();
            }, 0);
          }
        } else {
          // grid fill width of device
          this.oldWidth = Global.getDeviceWidth();
        }
        if (this.editViewParent?.editTabs?.length > 1) {
          for (const tab of this.editViewParent.editTabs) tab.collapseTab();
          // this.editViewParent.smartButtons.resizeSmartButton(event);
        }
        break;
      case IupicsTypeEvent.expandEvent:
        this.rowSelection = 'multiple';
        this.menuBarUiComponent.setVisibleButton(true);
        this.isGridCollapsed = false;

        if (!Global.isMobile()) {
          if (this.GridTabInfinityScrollUiComponent) {
            this.isTree = this.data.hasTree;
            let parentComp = this.DOMParentComponent;
            while (parentComp && !(parentComp instanceof EditViewUiComponent)) {
              parentComp = parentComp.DOMParentComponent;
            }

            this.GridTabInfinityScrollUiComponent.allColumns(event?.item?.data?.label === 'select');
          }
          if (this.calendarViewUiComponent) {
            setTimeout(() => {
              this.calendarViewUiComponent.calendar.updateSize();
            }, 0);
          }
        }

        if (this.editViewParent?.editTabs?.length > 1) {
          for (const tab of this.editViewParent.editTabs) tab.expandTab();
          // this.editViewParent.smartButtons.resizeSmartButton(event);
        }
        break;
      case IupicsTypeEvent.REFRESH_GRID:
        this.refreshGridAndParent();
        break;
      case IupicsTypeEvent.triggerAfterChange:
        this.notifierLinkedComponent.next({
          type: IupicsTypeEvent.selectDataChange,
          item: event.item,
        });
        break;
      case IupicsTypeEvent.UPDATE_ROWSAVED:
        this.editViewParent?.updateAll(false, this.tabId);
        this.updateRow(event.item ? event.item.dataStoreKey.recordId : this.selectedRecordId);
        break;
      default:
        break;
    }
  }

  refreshGridAndParent() {
    if (this.GridTabInfinityScrollUiComponent) {
      // setter la page courante
      this.GridTabInfinityScrollUiComponent.currentSelectPageIndex =
        this.GridTabInfinityScrollUiComponent.grid.api.getCurrentPage();
      if (this.editViewParent) this.editViewParent.updateAll(false);
      else this.GridTabInfinityScrollUiComponent.refresh(true);
    }
  }

  onRemoveComponent(event: IupicsEvent) {}

  updateRow(recordId: string) {
    this.GridTabInfinityScrollUiComponent.grid.api.forEachNode((node) => {
      const data = node.getRowData();
      if (data && data['Data_UUID'] == recordId) {
        const request: DataStoreRequest = {
          windowId: this.data.AD_Window_ID,
          record_id: recordId,
          parent_constraint: this.editViewParent?.currentDataStoreKey?.recordId ?? '',
          compiereRequest: {
            windowType: CompiereDataGridType.WINDOW,
            entityId: this.tabId,
            startRow: 0,
            endRow: 1,
            validation: this.getTabWhereclause(),
            windowCtx: this.getCurrentContext(),
          },
        };

        if (
          request.parent_constraint !== null &&
          request.parent_constraint !== undefined &&
          request.parent_constraint.length > 0
        ) {
          let filtersArray = request.parent_constraint.split('=');
          if (filtersArray.length <= 1) {
            const splittedRecord = request.parent_constraint.split(',');
            filtersArray = splittedRecord.length > 1 ? splittedRecord : [];
          }

          if (
            filtersArray.length == 2 &&
            !this.dataStoreService.getColumnCompiereDataGridFilterType(
              request.compiereRequest.entityId,
              filtersArray[0]
            )
          ) {
            request.parent_constraint = '';
          }
        }

        /**
         * @start_custo_code
         */
        if (this.data.AD_Window_ID === this.transportManagementWindowID) {
          this.GridTabInfinityScrollUiComponent.api.deselectAll();
          this.refreshGridAndParent();
        } else {
          /**
           * @end_custo_code
           */
          this.dataStoreService.getWindowSingleData(request).subscribe((dataStore) => {
            if (dataStore) {
              this.GridTabInfinityScrollUiComponent.grid.api.getRowNode(node.id).setData({ ...dataStore.data });

              for (let i = 1; i < this.container.DOMChildrenComponent.length; i++) {
                if (
                  this.container.DOMChildrenComponent[i] instanceof EditViewUiComponent &&
                  this.container.DOMChildrenComponent[i].tabId === this.tabId &&
                  this.container.DOMChildrenComponent[i]?.currentDataStoreKey?.parentId
                ) {
                  const parentColumnName =
                    this.container.DOMChildrenComponent[i].currentDataStoreKey.parentId.split(',')[0];
                  const parentId = this.container.DOMChildrenComponent[i].currentDataStoreKey.parentId.split(',')[1];

                  if (
                    dataStore.data[parentColumnName] &&
                    dataStore.data[parentColumnName].id !== parseInt(parentId, 10)
                  ) {
                    this.GridTabInfinityScrollUiComponent.grid.api.deselectAll();
                    this.refreshGridAndParent();
                  }
                }
              }
            } else {
              console.error('No data found!');
            }
          });
        }
      }
    });
  }

  displayHideFilters(isFilter) {
    this.GridTabInfinityScrollUiComponent?.displayHideFilters(isFilter);
  }

  displayHideMultiButton(event: { selected: boolean; data: {}[] }) {
    this.menuBarUiComponent?.setVisibleButtonMultiSelection(event.selected);
    this.multiSelectEmitter.emit(event);
  }

  refreshGrid(query?: SpecificGridQuery) {
    switch (this.viewType) {
      case ViewType.GRID:
        this.GridTabInfinityScrollUiComponent?.refresh(true, query);
        break;
      case ViewType.KANBAN:
        this.kanbanUiComponent?.refresh();
        break;
      case ViewType.CALENDAR:
        this.calendarViewUiComponent?.refresh();
        break;
      case ViewType.CHART:
        this.chartUiComponent?.refresh();
        break;
      case ViewType.CARDS:
        this.cardsUiComponent?.refreshCards();
        break;
      default:
        break;
    }
  }

  openExportDataModal(event: Event) {
    event.stopPropagation();
    if (this.checkAndExecuteOverride('export')) {
      return;
    }

    // Récupération des records sélectionnés
    this.exportIds = [];
    if (this.editViewParent?.currentDataStoreKey) {
      const splittedRecord = this.editViewParent.currentDataStoreKey.recordId.split(',');
      if (splittedRecord.length > 1) {
        const tableName = this.data.tableName;
        this.whereClause = '';
        if (tableName) {
          this.whereClause += tableName;
        }
        this.whereClause += '.' + splittedRecord[0] + '=' + splittedRecord[1];
      }
    }
    this.exportIds = this.GridTabInfinityScrollUiComponent.getExportSelections();
    this.displayExportDataModal = true;
  }

  /**
   * Find an edit which has the current tabId in the container
   * @param {any}container
   * @param {number}tabId
   * @returns {number} index
   */
  public isEditExist(): boolean {
    let find = false;
    let j = 1;
    while (!find && this.container.DOMChildrenComponent.length > j) {
      if (
        this.container.DOMChildrenComponent[j].DOMComponent.instance instanceof EditViewUiComponent &&
        this.container.DOMChildrenComponent[j].tabId === this.tabId
      ) {
        find = true;
      }
      j++;
    }
    return find;
  }

  openOtherEditViews() {
    if (this.container.activeTab.othersRecordId[0].tabId == this.tabId) {
      if (this.container.activeTab.othersRecordId[0].recordId === 'newRecord') {
        this.openNew();
      } else {
        this.forceOpenEditView(this.container.activeTab.othersRecordId[0].recordId);
        this.selectedRecordId = this.container.activeTab.othersRecordId[0].recordId;
      }
      this.container.activeTab.othersRecordId.splice(0, 1);
      if (this.container.activeTab.othersRecordId.length === 0) {
        this.container.activeTab.othersRecordId = undefined;
      }
    }
  }

  zoomAcrossPropagation(zoomInfo) {
    const nbChildren = zoomInfo.children.length;
    if (nbChildren > 0 && this.tabId == zoomInfo.children[nbChildren - 1].Tab_ID) {
      const concernedChild = zoomInfo.children[nbChildren - 1];
      zoomInfo.children.splice(-1, 1);
      const zoomInfoParam = {
        parentId: zoomInfo.dataUUID,
        windowId: concernedChild.Window_ID,
        dataUUID: concernedChild.Record_ID,
        record_id: concernedChild.Record_ID.split(',')[1],
        children: zoomInfo.children,
      };
      this.forceOpenEditView(concernedChild.Record_ID, zoomInfoParam);
    }
  }

  notifyGridTabAfterViewInitEmitter(gridTab: GridTabInfinityScrollUiComponent) {
    this.gridTabAfterViewInitEmitter.emit(gridTab);
  }

  switchViewType(viewType: ViewType) {
    this.initRequest = { ...this.request };
    this.viewType = viewType;
    this.initFromSwitch = true;
    if (viewType === ViewType.GRID) {
      this.GridTabInfinityScrollUiComponent.setView();
      this.GridTabInfinityScrollUiComponent?.createServerDataSource();
    }

    this.refreshGrid();
  }

  gridCellClicked(event) {
    this.gridViewCellClicked.emit(event);
  }

  //#endregion

  isOutsideClicked(event) {
    return !(
      this.panelCalendar.nativeElement.isSameNode(event.target) ||
      this.panelCalendar.nativeElement.contains(event.target) ||
      this.isNavIconClicked(event)
    );
  }

  isNavIconClicked(event: Event) {
    return (
      DomHandler.hasClass(event.target, 'p-datepicker-prev') ||
      DomHandler.hasClass(event.target, 'p-datepicker-prev-icon') ||
      DomHandler.hasClass(event.target, 'p-datepicker-next') ||
      DomHandler.hasClass(event.target, 'p-datepicker-next-icon') ||
      DomHandler.hasClass(event.target, 'p-datepicker-trigger') ||
      DomHandler.hasClass(event.target, 'ui-input-calendar')
    );
  }

  /**
   * mise à jour du display
   * @param dataStore noveau datastore à prendre en compte
   */
  updateDisplayLogic(dataStore?: DataStore) {
    if (this.data?.displayLogic) {
      this.isDisplayed = LogicEvaluator.evaluateLogic(
        this.getCurrentContext(dataStore) ?? null,
        this.data.displayLogic
      );
    } else {
      this.isDisplayed = true;
    }

    return this.isDisplayed;
  }

  getParentStore() {
    return this.editViewParent?.editTabs?.[0]?.dataStored ?? this.container?.dataStore ?? null;
  }

  /**
   * récupération du contexte complet du composant
   * @param dataStore nouveau datastore à prendre en compte
   */
  getCurrentContext(datastore?: DataStore) {
    let currentParent;
    let dataStored;
    /* dans le cas d'une grid dans une editview */
    if (this.editViewParent?.editTabs?.[0]) {
      dataStored = this.editViewParent.editTabs[0].dataStored;
      if (this.editViewParent) {
        currentParent = this.editViewParent;
      }
    } else if (this.container) {
      /* dans le cas d'une grid dans une form */
      dataStored = this.container.dataStore;
      if (this.container.parentComponent?.linkedComponents?.[0]) {
        currentParent = this.container.parentComponent;
      }
      /*cas d'une form dans une form */
      if (this.container?.parentComponent?.dataStore) {
        /*cas d'une form dans une form provenant d'un autocomplete */
        return EditViewUtils.mergeCurrentDataDeepCopy(
          dataStored.data,
          (this.container?.sourceComponent ?? this.container.parentComponent).getCurrentContext(),
          false
        );
      }
    }
    /*ajout du tabId */
    dataStored = datastore ?? dataStored;
    if (dataStored?.data) {
      dataStored = {
        ...dataStored,
        data: {
          ...dataStored.data,
          Parent_Tab_ID: this.tabId ?? this.parentTab?.tabId ?? null,
        },
      };
    }

    return EditViewUtils.getCurrentContext(currentParent, dataStored, this.connectorService.getIupicsUserContext());
  }

  /**
   * permet d'overrider l'action si il existe un process ou une form. La méthode retourne true si c'est le cas.
   * @param source indique quelle action est à overrider
   */
  checkAndExecuteOverride(source: string) {
    let formIdToOpen;
    let processIdToOpen;
    const ad_window_id = this.data ? '' + this.data.AD_Window_ID : '';

    this.#setFormAndProcessIdToOpen(source, ad_window_id, formIdToOpen, processIdToOpen);

    if (formIdToOpen !== undefined) {
      if (this.editViewParent?.updateModalDisplay) {
        this.editViewParent.updateModalDisplay(
          { key: 'displayFormUI', value: true, sourceComponent: { columnName: source } },
          { key: 'formId', value: formIdToOpen }
        );
      } else {
        this.updateModalDisplay({ key: 'displayFormUI', value: true }, { key: 'formId', value: formIdToOpen });
      }
    } else if (processIdToOpen !== undefined) {
      if (this.editViewParent?.updateModalDisplay) {
        this.editViewParent.updateModalDisplay(
          { key: 'displayProcessUI', value: true, sourceComponent: source },
          { key: 'processId', value: processIdToOpen }
        );
      } else {
        this.updateModalDisplay(
          { key: 'displayProcessUI', value: true },
          {
            key: 'processId',
            value: processIdToOpen,
          }
        );
      }
    }

    return formIdToOpen !== undefined || processIdToOpen !== undefined;
  }

  #setFormAndProcessIdToOpen(source: string, ad_window_id: string, formIdToOpen: number, processIdToOpen: number) {
    let key = undefined;
    switch (source) {
      case 'new':
        key = GridViewGetFormIDByTabID.OPEN_NEW;
        break;
      case 'copy':
        key = GridViewGetFormIDByTabID.COPY_DATA;
        break;
      case 'export':
        key = GridViewGetFormIDByTabID.EXPORT_DATA;
        break;
      case 'refresh':
        key = GridViewGetFormIDByTabID.REFRESH_DATA;
        break;
      case 'delete':
        key = GridViewGetFormIDByTabID.DELETE_DATA;
        break;
    }

    //? Maybe we should reconsider the way we reassign the formIdToOpen and processIdToOpen parameters
    //? It's generally a bad practice to reassign parameters
    formIdToOpen = this.config.getConstant(key + this.tabId);
    processIdToOpen = this.config.getConstant(key + this.tabId);
    if (formIdToOpen === undefined && processIdToOpen === undefined) {
      formIdToOpen = this.config.getConstant(key + ad_window_id);
      processIdToOpen = this.config.getConstant(key + ad_window_id);
    }
  }

  /**
   * mise à jour du readOnly
   * @param dataStore nouveau datastore à prendre en compte
   */
  updateReadOnlyLogic(dataStore?: DataStore) {
    let isReadOnly = false;
    const readOnlyLogic = this.data.readOnlyLogic;
    if (this.data.isReadOnly) {
      isReadOnly = true;
    } else if (readOnlyLogic) {
      isReadOnly = EditViewUtils.checkLogic(readOnlyLogic, this.getCurrentContext(dataStore));
    }
    this.isReadOnly = isReadOnly;
    if (this.parentTab) {
      this.parentTab.isReadOnly = this.isReadOnly;
    }
    if (!this.isTabTopLevel) {
      this.menuBarUiComponent.isReadOnly = this.isReadOnly;
      this.menuBarUiComponent.isInsertRecord = !this.isReadOnly;
      this.menuBarUiComponent.updateButtonLists();
    }
  }

  /**
   * mise à jour du IsDeletable
   * @param dataStore nouveau datastore à prendre en compte
   */
  updateIsDeleteableOnlyLogic(dataStore?: DataStore) {
    if (this.parentTab && this.parentTab.isDeleteable === false) {
      this.isDeleteable = false;
      return;
    }

    this.isDeleteable = this.data.isDeletableLogic
      ? EditViewUtils.checkLogic(this.data.isDeletableLogic, this.getCurrentContext(dataStore))
      : true;
  }

  updateAllLogic(datastore?: DataStore) {
    this.updateDisplayLogic(datastore);
    this.updateReadOnlyLogic(datastore);
    this.updateIsDeleteableOnlyLogic(datastore);
  }

  getTabWhereclause(validation = null) {
    if (this.initRequest?.validation) {
      validation = validation ? validation + ' and ' + this.initRequest.validation : this.initRequest.validation;
    }

    if (!validation && this.data.validationCode) {
      validation = LogicEvaluator.replaceVariables(
        this.data.validationCode,
        this.connectorService.getIupicsUserContext(),
        this.getCurrentContext()
      );
    }

    if (this.gridTabValidator && this.gridTabValidator.trim().length > 0) {
      validation = `${validation ? validation + ' and ' : ''}${this.gridTabValidator}`;
    }

    validation = LogicEvaluator.replaceVariables(
      validation,
      this.connectorService.getIupicsUserContext(),
      this.getCurrentContext()
    );

    return validation;
  }
}
