import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {
  CompiereDataGridFilterModel,
  CompiereDataGridFilterType,
  CompiereDataGridSortModelType,
} from '@compiere-ws/models/compiere-data-json';
import { CustomDesignItem, CustomDesignItemType } from '@iupics-components/models/custom-design';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { IupicsData, IupicsSpecificWindow } from '@iupics-manager/models/iupics-data';
import { DateUtils } from '@iupics-util/tools/date.utils';
import { SortModel } from '@iupics/apiz-grid';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-changelog-window-ui',
  templateUrl: './changelog-window-ui.component.html',
  styleUrls: ['./changelog-window-ui.component.scss'],
  standalone: true,
  imports: [ButtonModule, TooltipModule, TranslateModule],
})
export default class ChangelogWindowUIComponent extends SpecificWindowUiComponent implements OnInit, OnDestroy {
  @ViewChild('vcrGrid', { read: ViewContainerRef, static: true }) vcrGrid: ViewContainerRef;

  showTableChangeLog: boolean;
  isCopiedToClipBoard = false;
  changeLogFilterModel: { [columnName: string]: CompiereDataGridFilterModel };
  changeLogSortModel: SortModel[] = [];
  changeLogGridIsDisplay = false;

  auditsInfo = {
    created_by: '',
    updated_by: '',
    tableName: '',
    id_txt: '',
  };

  private customDesignItemGrid: CustomDesignItem = {
    vcr: 'vcrGrid',
    type: CustomDesignItemType.GRID,
    tableName: 'ChangeLog_Form_Table',
    cssClass: 'p-col-12',
    isLabelDisplay: false,
  };

  /**
   * Je récupère les informations pour gérer la construction moi même
   * @param specific
   * @param isCssOnComponent
   */
  buildWindow(specific: IupicsSpecificWindow, isCssOnComponent = true) {
    this.parentFormID = specific.AD_FormDetail_ID;
    this.title = specific.title;
    this.specificData = specific;
    this.initAuditInfo();
  }

  private initAuditInfo() {
    this.showTableChangeLog = false;
    this.changeLogSortModel = [];
    this.changeLogGridIsDisplay = false;
    if (this.parentComponent) {
      if (!this.store.isWindowNewData(this.parentComponent.currentDataStoreKey)) {
        const recordIds = this.parentComponent.currentDataStoreKey.recordId.split(',');
        if (recordIds && recordIds.length > 1) {
          if (
            this.parentComponent.editTabs &&
            this.parentComponent.editTabs.length > 0 &&
            this.parentComponent.editTabs[0].data
          ) {
            const table_id = (<IupicsData>this.parentComponent.editTabs[0].data).AD_Table_ID;
            const map = {};
            this.changeLogFilterModel = {
              AD_Table_ID: <CompiereDataGridFilterModel>{
                filterType: CompiereDataGridFilterType.SET,
                operators: [OperatorFilterType.EQUALS],
                values: [[table_id]],
              },
            };
            // dans le cas d'un recordId composé
            if (recordIds.length > 2) {
              let composedRecordId = '';
              for (let i = recordIds.length - 1; i < recordIds.length && recordIds[i - 1] !== undefined; i -= 2) {
                if (composedRecordId.length > 0) {
                  composedRecordId += ' AND ';
                }
                // composedRecordId += recordIds[i - 1] + '=' + recordIds[i];
                composedRecordId +=
                  recordIds[i - 1] +
                  '=' +
                  (isNaN(parseInt(recordIds[i], 10)) ? "'" + recordIds[i] + "'" : parseInt(recordIds[i], 10));
                map[recordIds[i - 1]] = recordIds[i];
              }
              this.changeLogFilterModel['Record2_ID'] = {
                filterType: CompiereDataGridFilterType.TEXT,
                operators: [OperatorFilterType.EQUALS],
                values: [[composedRecordId]],
              };
            } else {
              // dans le cas d'un recordId simple
              if (this.parentComponent.editTabs[0].data.tableName === 'AD_EntityType') {
                this.changeLogFilterModel['Record2_ID'] = {
                  filterType: CompiereDataGridFilterType.TEXT,
                  operators: [OperatorFilterType.EQUALS],
                  values: [recordIds[1]],
                };
                map[recordIds[0]] = recordIds[1];
              } else {
                this.changeLogFilterModel['Record_ID'] = {
                  filterType: CompiereDataGridFilterType.TEXT,
                  operators: [OperatorFilterType.EQUALS],
                  values: [[isNaN(parseInt(recordIds[1], 10)) ? recordIds[1] : parseInt(recordIds[1], 10)]],
                };

                map[recordIds[0]] = isNaN(parseInt(recordIds[1], 10)) ? recordIds[1] : parseInt(recordIds[1], 10);
              }
            }
            if (
              this.parentComponent.editTabs &&
              this.parentComponent.editTabs[0] &&
              this.parentComponent.editTabs[0].data &&
              !this.parentComponent.editTabs[0].data.isView
            ) {
              // region specific custom
              /**
               * @start_custo_code
               */
              this.store.getChangeLog(table_id, map).subscribe((res) => {
                // construction des infos updated et created
                this.auditsInfo.created_by =
                  res[0][1] +
                  ' - ' +
                  DateUtils.formatLocaleStr(new Date(res[0][0])) +
                  ' ' +
                  DateUtils.formatStr(new Date(res[0][0]), 'LTS');
                this.auditsInfo.updated_by =
                  res[0][3] +
                  ' - ' +
                  DateUtils.formatLocaleStr(new Date(res[0][2])) +
                  ' ' +
                  DateUtils.formatStr(new Date(res[0][2]), 'LTS');
                /**
                 * @end_custo_code
                 */

                if (res.length > 1) {
                  // construction de la grille des changements
                  this.showTableChangeLog = true;
                  const componentRef = this.createCustomDesignItem(this.customDesignItemGrid);
                  this.componentRefs.push(componentRef);
                }
              });
            }
            this.auditsInfo.tableName = this.parentComponent.editTabs[0].data.tableName;
            // construit les différents colonnes clé à afficher sous la forme "nomColonne:id - nomColonne2:id2"
            this.auditsInfo.id_txt = '';
            for (let i = 0; i < recordIds.length; i++) {
              if (this.auditsInfo.id_txt.length > 0) {
                this.auditsInfo.id_txt += ' - ';
              }
              this.auditsInfo.id_txt += recordIds[i] + ':' + recordIds[++i];
            }
            document.body.style.cursor = 'auto';
          }
        }
      } else {
        if (
          this.parentComponent.editTabs &&
          this.parentComponent.editTabs.length > 0 &&
          this.parentComponent.editTabs[0].data
        ) {
          this.auditsInfo.tableName = this.parentComponent.editTabs[0].data.tableName;
        }
        document.body.style.cursor = 'auto';
      }
    }
  }

  notifyFromGridAfterViewInit(gridView: GridViewUiComponent) {
    super.notifyFromGridAfterViewInit(gridView);

    gridView.isChangelog = true;

    if (gridView.data.columnName === 'ChangeLog_Form_Table') {
      this.changeLogSortModel = [
        {
          colId: 'Updated',
          sort: CompiereDataGridSortModelType.DESC,
        },
      ];

      this.applyFilter(gridView, {
        filterModel: this.changeLogFilterModel,
        sortModel: this.changeLogSortModel,
      });
    }
  }

  copyToClipBoard(str: string) {
    str = str.replace(/\:/g, '=').replace(/-/g, 'AND');
    EditViewUtils.copyToClipboard(str);
    this.isCopiedToClipBoard = true;
    setTimeout(() => {
      this.isCopiedToClipBoard = false;
    }, 3000);
  }
}
